import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby"

const LinkButton = styled(Link)`
  font-family : TellMeMore;
  font-size   : 3.3rem;
  color : black;
  text-transform : uppercase;
  border : 1px solid black;
  padding :2rem 4rem;
  text-decoration : none;
  letter-spacing: 0.5rem;

  &:hover {
    background : black;
    color : white;
  }
`;

const Submit = styled.button`
  font-family : TellMeMore;
  font-size   : 3.3rem;
  background : white;
  color : black;
  text-transform : uppercase;
  border : 1px solid black;
  padding :2rem 4rem;
  text-decoration : none;
  letter-spacing: 0.5rem;

  &:hover {
    background : black;
    color : white;
  }
`;

export default LinkButton;

export { Submit };
